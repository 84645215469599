@font-face {
  font-family: gagalin;
  src: url(Gagalin-Regular.otf);
}

@font-face {
  font-family: roboto_regular;
  src: url(RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: roboto_bold;
  src: url(RobotoCondensed-Bold.ttf);
}

@font-face {
  font-family: roboto_italics;
  src: url(RobotoCondensed-Italic.ttf);
}

.match-table {
  font-family: roboto_regular;
}

.match-table-td,
.match-table-th,
.match-table-tr {
  text-align: center;
}

.match-table-tr:nth-child(even) {
  background-color: lightgray;
}

.h1-match {
  font-family: gagalin;
  color: black;
  display: inline;
  font-size: 80px;
  padding: 25px 0px 50px 0px;
}

h2 {
  font-family: roboto_italics;
  padding: 25px 0px 25px 0px;
}
