@font-face {
    font-family: circular;
    src: url(Circular-Std-Font.otf);
}

@font-face {
    font-family: roboto_bold;
    src: url(RobotoCondensed-Bold.ttf);
}

.navbar-main {
    font-family: roboto_bold;
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 20px;
    background-color: maroon;
    height: 50px;
}

.nav-button {
    height: 15px;
}

.siteLogo {
    height: 65px;
    width: 85px;
}
