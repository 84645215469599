@font-face {
  font-family: roboto_regular;
  src: url(RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: roboto_bold;
  src: url(RobotoCondensed-Bold.ttf);
}

@font-face {
  font-family: roboto_italics;
  src: url(RobotoCondensed-Italic.ttf);
}

@font-face {
  font-family: gagalin;
  src: url(Gagalin-Regular.otf);
}

.season-card {
  font-family: roboto_regular;
}

.season-button {
  background-color: maroon;
  color: white;
  margin-left: 15px;
  margin-bottom: 10px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.season-button:hover {
  color: maroon;
  background-color: white;
  border: 2px solid maroon;
}

.season-container {
  padding: 25px 25px 25px 50px;
}

.h1-season {
  font-family: gagalin;
  font-size: 80px;
}

h2 {
  font-family: roboto_italics;
}
