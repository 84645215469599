.card {
  vertical-align: middle;
  justify-content: flex-start;
}

.card-title {
  font-weight: bold;
  padding: 15px;
}

.card-text {
  padding: 0px 15px 5px 15px;
}

.card-img-cust {
  min-height: 100%;
  max-height: 400px;
  width: 100%;
}
