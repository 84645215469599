@font-face {
  font-family: roboto-regular;
  src: url("/src/app/common/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: gagalin;
  src: url("/src/app/common/fonts/Gagalin-Regular.otf");
}

.h-home {
  font-family: gagalin;
  color: black;
  display: inline;
  font-size: 80px;
  padding: 25px 0px 50px 0px;
}

.home-img {
  width: 100%;
}

.h4-home {
  font-family: roboto-regular;
  font-weight: bold;
}

.list-home {
  font-family: roboto-regular;
}

.center-header {
  display: block;
  text-align: center;
}

.tile {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  height: fit-content;
  box-shadow: 0 4px 8px 0 #000000, 0 6px 20px 0 #000000;
  border: 1px solid #800000;
  background-color: #fff;
}

.tile-img {
  margin-bottom: 10px;
  height: auto;
  box-shadow: 0 4px 8px 0 #000000, 0 6px 20px 0 #000000;
  border: 1px solid #800000;
  background-color: #fff;
}

.div-top {
  padding-top: 25px;
}

.home-table {
  font-family: roboto-regular;
}

.home-td,
.home-th,
.home-tr {
  text-align: center;
  font-family: roboto-regular;
}

.home-tr:nth-child(even) {
  background-color: lightgray;
}
