@font-face {
    font-family: Barlow;
    src: url('/src/app/common/fonts/Barlow-Black.ttf');
}

@font-face {
    font-family: Baloo;
    src: url('/src/app/common/fonts/static/BalooBhaijaan2-SemiBold.ttf');
}

@font-face {
    font-family: Baloo-Bold;
    src: url('/src/app/common/fonts/static/BalooBhaijaan2-ExtraBold.ttf');
}

body {
    margin: 0;
    font-family: Barlow, Baloo, Baloo-Bold, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
