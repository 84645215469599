@font-face {
  font-family: gagalin;
  src: url(Gagalin-Regular.otf);
}

@font-face {
  font-family: roboto_regular;
  src: url(RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: roboto_bold;
  src: url(RobotoCondensed-Bold.ttf);
}

@font-face {
  font-family: roboto_italics;
  src: url(RobotoCondensed-Italic.ttf);
}

.h1-about {
  font-family: gagalin;
  color: black;
  display: inline;
  text-align: center;
  font-size: 80px;
}

.h2-about {
  font-family: roboto_regular;
  color: black;
  display: inline;
  float: inline-end;
  font-size: 40px;
}

p {
  font-family: roboto_regular;
  font-size: 20px;
}

figcaption {
  font-family: roboto_italics;
  font-size: 14px;
  padding: 10px 25px 25px 50px;
}

.img-about {
  max-height: 900px;
  width: auto;
  padding: 50px 25px 10px 50px;
  justify-content: center;
}

.about-container {
  padding: 25px 25px 25px 50px;
}

.right-half {
  float: left;
  width: 50%;
  padding-bottom: 50px;
}

.left-half {
  float: left;
  width: 50%;
  padding-bottom: 50px;
}
