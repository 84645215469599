@font-face {
  font-family: gagalin;
  src: url(Gagalin-Regular.otf);
}

@font-face {
  font-family: roboto_regular;
  src: url(RobotoCondensed-Regular.ttf);
}

.error-404 {
  font-family: gagalin;
  font-size: 180px;
  text-align: center;
}

.p-404 {
  font-family: roboto_regular;
  text-align: center;
}
